import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";

import { configureChains, createClient, WagmiConfig } from "wagmi";

import { bsc } from "wagmi/chains";
import React, { useState, useEffect, createContext } from "react";


import "./App.css";
import Login from "./components/Login";
import MintPage from "./components/MintPage";
import contractABI from './Assets/abi.json'

export const appContext = createContext();

const chains = [bsc];

function App() {

  // Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "41527ff5adcc91351d6fffdb6b5d3265" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: "web3Modal", chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

  const [login, setLogin] = useState(false);
  const [providerVar, setProviderVar] = useState();
  const [accounts, setAccounts] = useState([]);
  const [totalMinted, setTotalMinted] = useState("...");
  const [accountAddress, setAccountAddress] = useState(null);
  const [isMetamaskAvailable, setIsMetamaskAvailable] = useState(null);

  const contractAddress = "0x025a8F2C1d8d8d07A7D4E090edb9BA2c0F39E742";

  return (
    <appContext.Provider
      value={[
        login,
        setLogin,
        providerVar,
        setProviderVar,
        accounts,
        setAccounts,
        totalMinted,
        setTotalMinted,
        contractAddress,
        contractABI,
        accountAddress,
        isMetamaskAvailable, 
        setIsMetamaskAvailable
      ]}
    >
      <>
        <div className="App">
        <WagmiConfig client={wagmiClient}>
          {login ?
            <div>
              <MintPage />
            </div>
            :
            <div>
              <Login />
            </div>
          }
        </WagmiConfig>
        </div>
      </>
      <Web3Modal
        projectId="41527ff5adcc91351d6fffdb6b5d3265"
        ethereumClient={ethereumClient}
      />
    </appContext.Provider>
  );
}

export default App;

