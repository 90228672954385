import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import { appContext } from "../App";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { providers } from "ethers";
import connectbtn from "../Assets/connect-wallet.png";
import title from "../Assets/title.png";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";

const Login = () => {

  const { open } = useWeb3Modal()

  const [error, setError] = useState("");
  const [saleComplete, setSaleComplete] = useState(false);
  const [loading, setLoading] = useState(true)

  const [login, setLogin, provider, setProvider, accounts, setAccounts, totalMinted, setTotalMinted, contractAddress, contractABI, , isMetamaskAvailable, setIsMetamaskAvailable] =
    useContext(appContext);

  const rpcURL = "https://bsc-dataseed1.binance.org/";
  // const rpcURL = "https://data-seed-prebsc-1-s1.binance.org:8545";
  // const rpcURL = "http://127.0.0.1:7545";
  const chainID = 56

  useAccount({
    onConnect({ address}) {
      setLogin(true)
      setAccounts(address)
    }
  })

  useEffect(() => {
    getTotalSupply();
  }, []);

  async function ConnectWallet() {
    if (!login) {
      open()
    }
  }

  async function getTotalSupply() {
    const contract = new ethers.Contract(
      contractAddress,
      contractABI,
      new ethers.providers.getDefaultProvider(
        rpcURL
      )
    );
    try {
      const response = (await contract.totalSupply()).toString();
      setTotalMinted(response);
      setLoading(false);
      if (response === 6000) {
        setSaleComplete(true);
      }
      // console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="Container">

      <div className="transparentBox">
        {/* <img className="frame" src={frame}/> */}
        {/* <div className="logoDiv"> */}
        <img className="logoDiv" src={title} />
        {/* </div> */}
        <div>
          <p style={{ marginBottom: "120px", marginTop: "120px" }}> {totalMinted}/5700 NFTs Minted!</p>
        </div>
        <div>
          <p style={{ marginBottom: "20px" }}>Connect Wallet To Mint!</p>
        </div>
        <div>
          <img src={connectbtn}
            className="buttonConnect"
            onClick={() => {
              if (!saleComplete && !loading)
                ConnectWallet();
            }}
          />
          {/* {!loading?<>{saleComplete?<>Sale Completed..</>:<>Connect Wallet</>}</>:<>...</>} */}
        </div>
        <p style={{ marginRight: "auto", marginLeft: "auto", marginTop: '35px', letterSpacing: "1.5px", color: "yellow" }}>{error}</p>
      </div>
    </div>
  );
};

export default Login;

